import store from '../store'
import { bounds, qs, qsa } from '../utils'
import { Emitter } from '../core'
import { Flip } from 'gsap/Flip'
import gsap from 'gsap'

export const animateIn = (animation) => {
  gsap.registerPlugin(Flip)
  gsap.set('.page', { autoAlpha: 1 })
  const vw = (80 * 100) / 1440

  switch (animation) {
    case 'hero':
      const hero = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
          Emitter.emit('animation:ended', qsa('.marquee-item'))
        },
      })

      gsap.set('.hero-intro', { display: 'none' })
      hero.from('.hero-title .chr-1', {
        duration: 0.8,
        y: `-${vw * 1.07}vw`,
        ease: 'power3.inOut',
        delay: 0.5,
      })

      hero.from(
        '.hero-title .chr-2',
        {
          duration: 0.8,
          y: `${vw * 1.07}vw`,
        },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-3',
        { duration: 0.8, y: `-${vw * 3.25}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-4',
        { duration: 0.8, y: `${vw * 3.25}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-5',
        { duration: 0.8, y: `-${vw * 2.15}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-6',
        { duration: 0.8, y: `${vw * 2.18}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-7',
        { duration: 0.8, y: `-${vw * 4.31}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-8',
        { duration: 0.8, y: `${vw * 4.33}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )
      hero.from(
        '.hero-title .chr-9',
        { duration: 0.8, y: `${vw * 0}vw`, ease: 'power3.inOut' },
        '-=0.8',
      )

      hero.fromTo(
        '.header',
        { y: '-100%' },
        { duration: 0.8, y: '0%' },
        '-=0.4',
      )

      hero.fromTo(
        '.hero-app',
        { duration: 0.8, y: '10%', autoAlpha: 0 },
        { duration: 0.8, y: '0%', autoAlpha: 1 },
        '-=0.8',
      )

      hero.from(
        '.hero-description .line-0',
        {
          duration: 0.8,
          y: 20,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.5',
      )

      hero.from(
        '.hero-description .highlight-line',
        {
          duration: 0.6,
          scaleX: 0,
          transformOrigin: 'left',
          ease: 'power2.inOut',
        },
        '-=0.8',
      )

      hero.from('.hero-button', { duration: 0.5, y: 20, autoAlpha: 0 }, '-=0.5')
      hero.from(
        '.hero-container',
        { duration: 0.6, y: '20%', autoAlpha: 0 },
        '-=0.65',
      )
      //hero.progress(0.6)
      hero.play()

      break

    case 'resolve':
      const resolve = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      resolve.from('.resolve-title .char-1', {
        duration: 0.45,
        y: '120%',
        stagger: 0.015,
        delay: 0.5,
      })

      resolve.fromTo(
        '.header',
        { y: '-100%' },
        { duration: 0.8, y: '0%' },
        '-=0.4',
      )

      resolve.play()

      break

    case 'company':
      const companyLines = qsa('.company-title .line-0')
      const company = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      company.from('.company-bg', {
        duration: 2,
        backgroundColor: '#0d1815',
        ease: 'power3.inOut',
      })

      companyLines.forEach((line, i) => {
        const chars = qsa('.char-2', line)

        company.from(
          chars,
          {
            duration: 0.45,
            y: '100%',
            stagger: 0.05,
          },
          i * 0.15,
        )
      })

      company.fromTo(
        '.header',
        { y: '-100%' },
        { duration: 0.8, y: '0%' },
        '-=1',
      )

      company.play()

      break

    case 'title':
      const titleLines = qsa('.title-txt .line-0')
      const form = qs('.form-block')
      const title = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      titleLines.forEach((line, i) => {
        const chars = qsa('.char-1', line)

        title.from(
          chars,
          {
            duration: 0.45,
            y: '100%',
            stagger: 0.05,
          },
          i * 0.15,
        )
      })

      if (form)
        title.from(form, { duration: 0.8, autoAlpha: 0, y: '50%' }, '-=0.5')

      title.fromTo(
        '.header',
        { y: '-100%' },
        { duration: 0.8, y: '0%' },
        '-=0.4',
      )

      title.play()

      break

    case 'getstarted':
      const getstarted = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      getstarted.from('.getstarted-title .char-1', {
        duration: 0.45,
        y: '105%',
        stagger: 0.05,
        delay: 0.5,
      })

      getstarted.from(
        '.getstarted-description .line-0',
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.25',
      )

      getstarted.from(
        '.getstarted-button',
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
        },
        '-=0.2',
      )

      getstarted.from(
        '.getstarted-buttons',
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
        },
        '-=0.2',
      )

      getstarted.from(
        '.getstarted-card',
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.2',
      )

      getstarted.fromTo(
        '.header',
        { y: '-100%' },
        { duration: 0.8, y: '0%' },
        '-=0.6',
      )

      getstarted.play()

      break
  }
}
