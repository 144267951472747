import store from '../store'
import * as Taxi from '@unseenco/taxi'
import { Page } from '../renders'
import { Fade } from '../transitions'
import { CustomEase } from '../vendor/CustomEase'
import { qs, qsa, Sniffer, Grid, getViewport, getWindowSizes } from '../utils'
import {
  Emitter,
  GlobalVRaf,
  GlobalRaf,
  Scroll,
  VScroll,
  Mouse,
  GlobalResize,
  AssetLoader,
} from './'
import { Smooth, Noise } from '../components'
import lazySizes from 'lazysizes'
import gsap from 'gsap'
let singleton = null

export default class Core {
  constructor(obj = {}) {
    // Need to find a way to make this work with Obj
    const vs = true

    if (!singleton) {
      singleton = this
    } else {
      return singleton
    }

    this.setupStore()
    gsap.registerPlugin(CustomEase)

    this.ready = false

    // Store
    this.store = store
    // Grid cmd+g to activate
    this.grid = new Grid({
      background: '#a25b4c',
      desktop: {
        columns: 18,
      },
      mobile: {
        columns: 4,
        gutter: 27,
        size: 390,
      },
    })

    // Global Request Animation Frame
    this.raf = vs ? new GlobalVRaf() : new GlobalRaf() // When using Lenis
    // Global Scroll
    this.scroll = vs
      ? new VScroll({ smooth: !store.sniff.isDevice })
      : new Scroll() // Lenis

    this.smooth = new Smooth()
    // Global Mouse
    this.mouse = new Mouse()
    // Global Resize
    this.resize = new GlobalResize()
    // Asset Loader (Needs a data file data.yml)
    this.loader = new AssetLoader()
    // Ease for the Project
    this.pageEasing = CustomEase.create(
      'custom',
      'M0,0 C0.1,0 0.148,0.034 0.182,0.136 0.216,0.238 0.239,0.685 0.448,0.886 0.55,0.984 0.704,1 1,1',
    )

    // Taxi
    this.taxi = new Taxi.Core({
      links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore])',
      renderers: {
        default: Page,
        page: Page,
      },
      transitions: {
        default: Fade,
      },
      // removeOldContent: false
    })

    this.setupLazySizes()
    this.init()
  }

  setupStore() {
    // Initial Settings
    store.sizes.vw = getViewport().width
    store.sizes.vh = getViewport().height
    store.breakpoints = getWindowSizes()
    store.sniff = Sniffer.sniff
    store.msgs.rotate = qs('.-rotate')
    store.preloader = {
      el: qs('.preloader'),
      frames: qsa('.preloader-frame'),
    }
  }

  setupLazySizes() {
    // Update clases
    lazySizes.cfg.lazyClass = 'lazy'
    lazySizes.cfg.loadedClass = 'loaded'
  }

  init() {
    // Initialize core methods
    this.raf.on()
    this.scroll.on()
    // this.mouse.on() // This was causing an issue when loading the page: Uncaught SecurityError: Failed to read a named property 'addEventListener' from 'Window': Blocked a frame with origin "https://integrate.co" from accessing a cross-origin frame.
    // I can't find any reason why this needs to be used, so I'm just going to comment it out for now as it's cuasing the site to not load.

    this.noise = new Noise()

    this.loader.on().then((responses) => {
      setTimeout(() => {
        this.ready = true
        Emitter.emit('loaded')
      }, 100)
    })
  }
}
